import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Status from "../components/status"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes
  const post = data.allMarkdownRemark.nodes[0]

  console.log(post)

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="All posts" />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Wedding Info" />
        <article
          className="post-list-item"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header>
            <h2>
              <Link to={post.fields.slug} itemProp="url">
                <span itemProp="headline">{post.title}</span>
              </Link>
            </h2>
            <small>Last updated {post.frontmatter.date}</small>

          </header>

          <Status/>
          <section dangerouslySetInnerHTML={{
                __html: post.html || post.excerpt,
              }}
              itemProp="description"
            />
        </article>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: {slug: {eq: "/hello-world/"}}) {
      excerpt
      fields {
        slug
      }
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY hh:mm a")
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        html
        frontmatter {
          date(formatString: "MMMM DD, YYYY hh:mm a")
          title
          description
        }
      }
    }
  }
`
