import * as React from "react"
import styled from "styled-components"

const links = [
  {
    moment: "Ceremony and Reception",
    time: "From 3pm",
    venue: "Berry School of Arts",
    url: "https://goo.gl/maps/cbwFHf4uXcz9DSRz7",
    address: "19 Alexandra St, Berry NSW",
  }
]

const Status = () => {

  return (
    <StatusWrap>
      {links.map(link => (
        <StatusCel key={link.url}>
          <span>
            <Moment>
              {link.moment}
            </Moment>
            <Time>
              {link.time}
            </Time>
            <Venue>
              {link.venue}
            </Venue>
            <Address
              href={`${link.url}`}
            >{link.address}
            </Address>
          </span>
        </StatusCel>
      ))}
    </StatusWrap>
  )
}

const StatusWrap = styled.div`
  @media screen and (min-width: 42rem){
    /* display: flex; */
  }
`

const StatusCel = styled.div`
  background-color: var(--color-primary);
  outline: 1px solid #fff;
  padding: var(--spacing-6);
  display: block;
  
  @media screen and (min-width: 42rem){
    width: 100%;
    /* display: flex; */
  }
`

const Moment = styled.div`
  color: #fff;
  font-weight: var(--fontWeight-black);
  text-transform: uppercase;
`
const Time = styled.div`
  color: #fff;
  font-size: var(--fontSize-6);
  font-weight: var(--fontWeight-bold);
  margin-bottom: var(--spacing-4);
`
const Venue = styled.div`
  color: #fff;
  font-size: var(--fontSize-1);
  font-weight: var(--fontWeight-semibold);
  line-height: var(--lineHeight-tight);
`
const Address = styled.a`
  display: inline-block;
  color: #fff;
  line-height: var(--lineHeight-tight);
  margin-top: var(--spacing-2);
`

export default Status
